:root {
  --color-dark: #171c1f;
  --color-orange: #e04700;
  --color-light: #edf7ef;
  --color-box-focus: 0 0 0 4px rgba(11, 156, 240, 0.6);
  --color-box-focus-less: 0 0 0 3px rgba(11, 156, 240, 0.6);
  --color-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

body,
html {
  background-color: var(--color-light);
  min-height: calc(100vh - 90px);
}

.App {
  margin-top: 90px;
  margin-bottom: 32px;
  padding: 0 20%;
  position: relative;
}

/* Header/navbar */
.header {
  background-color: var(--color-dark);
  top: 0;
  left: 0;
  width: calc(100% - 32px);
  padding: 16px;
  box-shadow: var(--color-shadow);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  z-index: 9999999;
}
.header__title {
  font-size: 32px;
}
.header__button {
  margin: 0 8px;
  color: var(--color-dark);
  background-color: var(--color-light);
  border-radius: 8px;
  padding: 8px 16px;
  text-decoration: none;
  transition: 0.1s all ease-in-out;
}
.header__button:hover {
  background-color: var(--color-orange);
  color: var(--color-light);
}
.mobile__nav__contents .header__button {
  width: calc(100% - 32px);
  margin: 16px 0;
  text-align: center;
  font-size: 20px;
  padding: 16px;
}
.hamburgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background-color: var(--color-light);
  border-radius: 8px;
}
.menuContents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 32px;
}
.hamburgerMenu:hover {
  cursor: pointer;
  box-shadow: var(--color-box-focus);
}
.menuRow {
  position: relative;
  width: 32px;
  height: 6px;
}
.menuRow::before {
  content: "";
  width: 32px;
  height: 3px;
  border-radius: 3px;
  background-color: rgb(59, 59, 59);
  position: absolute;
  top: 2px;
  left: 0;
  opacity: 1;
  transition: 0.1s all ease-in-out;
}
.mobile__nav {
  height: 0;
  overflow: hidden;
  transition: 0.2s all ease-in-out;
  position: fixed;
  width: 100%;
  left: 0;
  height: 0;
  transform-origin: top;
  transform: translateY(16px);
  background-color: var(--color-dark);
}
.mobile__nav__contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 32px;
  transition: 0.2s all ease-in-out;
}
/* If the mobile nav is open */
.is--open .mobile__nav {
  height: 100%;
}
.is--open .mobile__nav__contents {
  transform: scaleY(1);
  padding: 32px;
}
.is--open .row__top::before {
  transform: rotate(45deg);
  top: 8px;
}
.is--open .row__middle::before {
  opacity: 0;
}
.is--open .row__bottom::before {
  transform: rotate(-45deg);
  top: -4px;
}

/* Sections */
.intro {
  font-size: 24px;
  margin: 0;
}
.experience {
  border: 2px solid var(--color-dark);
  border-radius: 16px;
  box-shadow: var(--color-shadow);
}
.experience p {
  margin: 0;
  padding: 8px;
}
.experience p:nth-of-type(even) {
  background-color: lightgray;
}
.experience p:first-of-type {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.experience p:last-of-type {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.experience a {
  color: var(--color-dark);
}

/* Flex */
.flex {
  display: flex;
}
.flex.row {
  flex-direction: row;
}
.flex .half-width {
  width: 45%;
}
.flex.between {
  justify-content: space-between;
  flex: 1;
}

/* Section */
.section {
  width: 100%;
}
.section__title {
  font-size: 24px;
  text-align: center;
  padding: 8px 0 18px 0;
}

/* Project */
.project {
  transition: 0.1s all ease-in-out;
  margin-bottom: 16px;
}
.project__title__container {
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
}
.project__title {
  font-size: 20px;
}
.project__tag {
  font-size: 12px;
  margin: 0 4px;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: var(--color-light);
  color: black;
}

.project__title__container::before {
  content: "";
  border-radius: 4px;
  border-top: 3px solid var(--color-dark);
  border-right: 3px solid var(--color-dark);
  position: absolute;
  right: 14px;
  bottom: 18px;
  height: 14px;
  width: 14px;
  transform: rotate(45deg);
  transition: 0.2s all ease-in-out;
}
.project__title__container:hover {
  cursor: pointer;
  box-shadow: var(--color-box-focus);
}
.project__summary {
  overflow: hidden;
  height: 0;
  padding: 0 16px;
  transition: 0.2s all ease-in-out;
  position: relative;
}
.project__summary::before {
  content: "";
  position: absolute;
  right: 50%;
  width: 0;
  top: 0;
  height: 2px;
  background-color: var(--color-dark);
  transition: 0.2s all ease-in-out;
}
.project__links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.project__links a {
  padding: 0 8px;
}
.project__img {
  max-width: calc(100% - 16px);
  padding: 8px;
  border: 2px solid var(--color-dark);
  border-radius: 8px;
  margin-top: 8px;
}
/* Open Project style */
.project.is--open .project__title__container::before {
  transform: rotate(135deg);
  bottom: 22px;
}
.project.is--open .project__summary {
  height: auto;
  padding: 16px;
}
.project.is--open .project__summary::before {
  width: 90%;
  right: 5%;
}

/* Education */
.education__item {
  margin-bottom: 16px;
  border: 2px solid var(--color-dark);
  border-radius: 8px;
  box-shadow: var(--color-shadow);
}
.education__title {
  padding: 16px;
  font-size: 20px;
  background-color: var(--color-dark);
  color: var(--color-light);
}
.education__details {
  padding: 16px;
}
.education__item__titla {
  font-weight: bold;
}
.education__item__titla:not(:first-of-type) {
  margin-top: 12px;
}

/* Links */
.demo_link {
  color: white;
  transition: 0.1s all ease-in-out;
}
.demo_link:hover {
  color: rgb(5, 5, 158);
}
.inline_link,
.inline_link:not(:visited),
.inline_link:visited {
  color: var(--color-orange);
}

.version {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Responsive settings */

/* Tablet */
@media (max-width: 1024px) {
  .App {
    padding: 0 10%;
  }
  .flex.mobile--column {
    flex-direction: column;
  }
  .flex .half-width {
    width: 100%;
  }
}
/* Mobile */
@media (max-width: 720px) {
  .App {
    padding: 0 16px;
  }
  .flex.mobile--column {
    flex-direction: column;
  }
  .flex .half-width {
    width: 100%;
  }
  .project__tags {
    margin: 8px 0 0 0;
  }
  .version {
    width: 100%;
    text-align: center;
    right: 0;
  }
}
